<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6.text-center.d-block(v-html="$t('adAccept.title2')")

		p.mb-4(v-html="$t('adAccept.desc')")

		ul.list-decimal
			li(v-for='i in list' v-html='i')
		
		span.font-weight-medium {{ $t('forms.requisites') }}:
		company-data
		br
		br
		pw-btn-up
</template>

<script>
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('adAccept.title')}},
	components: {
		CompanyData,
		PwBtnUp
	},
	data() {
		return {}
	},
	computed: {
		list () {
			let list = _.cloneDeep(bus.$t('adAccept.list'));

			_.each(list, (m, i) => {
				list[i] = list[i].replace(/\$\{bus\.botPayName\}/, bus.botPayName);
				list[i] = list[i].replace(/\$\{bus\.botPay\}/, bus.botPay);
				list[i] = list[i].replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
				list[i] = list[i].replace(/\$\{bus\.botSupport\}/, bus.botSupport);
			})

			return list;
		}
	},
	created() {
		window.scrollTo(0, 0);
		
		this.viewGeoIpRussia();
	}
}
</script>
